<template>
    <div id="app">
        <v-app id="inspire" :style="{background: '#FFC324'}">
            
            <v-container>
                <v-card v-show="register">
                    <v-tabs
                        v-model="tab"
                        background-color="#273b81"
                        centered
                        dark
                        icons-and-text
                    >
                    <v-tabs-slider color="#ef2472"></v-tabs-slider>

                        <v-tab href="#tab-1">
                            สมาชิก Insurefriend
                        </v-tab>

                        <v-tab href="#tab-2">
                            สมาชิกใหม่
                        </v-tab>

                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item
                            value="tab-1"
                        >
                            <v-card-text>
                                <v-form v-model="verifyEdit" ref="formEdit" lazy-validation>
                                    <v-row no-gutters>
                                        <v-col cols="12" md="12">
                                            <v-text-field label="เบอร์โทรศัพท์" :rules="rulesBlank" v-model="itemEdit.cus7" clearable></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <v-text-field label="รหัสอ้างอิง" :rules="rulesBlank" v-model="itemEdit.cus15" hint="สอบถามรหัสอ้างอิงจากผู้ดูแลของคุณ" persistent-hint clearable></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                            <v-divider class="mt-5"></v-divider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    text
                                    @click="regis"
                                >
                                    ตกลง
                                </v-btn>
                            </v-card-actions>
                        </v-tab-item>

                        <v-tab-item
                            value="tab-2"
                        >
                            <v-card-text>
                                <v-form v-model="verifyEdit" ref="formEdit" lazy-validation>
                                    <v-row no-gutters>
                                        <v-col cols="12" md="12">
                                            <v-text-field label="เบอร์โทรศัพท์" :rules="rulesBlank" v-model="itemNew.phone" clearable></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="12" v-if="tk != ''">
                                            <v-text-field label="OTP" :rules="rulesBlank" v-model="itemNew.otp" clearable></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                            <v-divider class="mt-5"></v-divider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    text
                                    @click="regis2"
                                    :loading="loading"
                                >
                                    ตกลง
                                </v-btn>
                            </v-card-actions>
                        </v-tab-item>

                    </v-tabs-items>
                </v-card>
            </v-container>

            <v-dialog v-model="alert" persistent max-width="500px">
                <v-card>
                    <v-card-title class="text">{{message}}</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDialog">ปิด</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-app>
    </div>
</template>

<script>
    export default {
        data: function() {
            return {
                tab: null,
                register:false,
                verifyEdit: true,
                rulesBlank: [
                        v => !!v || 'Required',
                    ],
                itemEdit: {},
                cus12: '',
                itemNew: {},
                tk: '',
                loading: false,
                redirect: false,
                alert: false,
                message: '',
            };
        },
        beforeCreate(){
            this.$liff.init({ liffId: "1656826746-bneWkQgB" }, () => {
                if (this.$liff.isLoggedIn()) {
                    const params = new URLSearchParams()
                    params.append('id_token', this.$liff.getIDToken())
                    params.append('client_id', '1656826746')
                    const headers = { 
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                    var liff = this.$liff;
                    var app = this;
                    this.$axios.post(
                        'https://api.line.me/oauth2/v2.1/verify',
                        params,
                        headers
                    ).then(async function (response) {
                        if(!response.data){
                            alert("ERROR Please contact admin")
                        }else{
                            var result = response.data
                            app.cus12 = result.sub
                            await app.login()
                        }
                    }).catch(function () {
                        liff.logout()
                        liff.login()
                    })

                } else {
                    this.$liff.login();
                }
            }, err => console.error(err.code));
        },

        // beforeMount:async function(){
        //     this.cus12 = 'aaa';
        //     this.login();
        // },

        methods: {

            login:async function(){
                var app = this;
                let formData = new FormData();
                formData.append('cus12', app.cus12);
                formData.append('mode', 'login');
                await this.$axios.post(this.$hostUrl+'set/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        var output = response.data
                        console.log(output)
                        if(output == "register"){
                            app.register = true
                        } else if(output == "success"){
                           window.location.href = "/"
                        }
                        
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            regis:async function(){
                var app = this;
                if(!this.$refs.formEdit.validate()) return;
                let formData = new FormData();
                formData.append('cus12', app.cus12);
                formData.append('cus15', app.itemEdit.cus15);
                formData.append('cus7', app.itemEdit.cus7);
                formData.append('mode', 'register');
                await this.$axios.post(this.$hostUrl+'set/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        app.message = response.data
                        if(app.message == 'ลงทะเบียนเรียบร้อยแล้ว') app.redirect = true
                        app.alert = true
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            regis2:async function(){
                if(!this.$refs.formEdit.validate()) return;
                var app = this;
                var mode = 'getOTP';
                app.loading = true;
                let formData = new FormData();
                formData.append('cus12', app.cus12);
                formData.append('phone', app.itemNew.phone);
                formData.append('otp', app.itemNew.otp);
                formData.append('tk', app.tk);
                if(app.tk != '') mode = 'checkOTP';
                formData.append('mode', mode);
                console.log(mode)
                await this.$axios.post(this.$hostUrl+'set/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        var result = response.data
                        if(result.startsWith('TK|')){
                            app.tk = result.substring(3);
                        } 
                        else if(result == 'phone'){
                            app.message = 'เบอร์โทรศัพท์นี้ได้ทำการลงทะเบียนไว้แล้ว'
                            app.alert = true
                        }
                        else if(result == 'success'){
                            app.message = 'ลงทะเบียนเรียบร้อยแล้ว'
                            app.redirect = true
                            app.alert = true
                        } 
                        else if(result == 'invalid'){
                            app.message = 'OTP ไม่ถูกต้อง'
                            app.alert = true
                        }
                        else {
                            app.tk = ''
                            app.itemNew.otp = ''
                            app.message = 'ไม่สามารถทำรายการได้ กรุณาลองอีกครั้ง'
                            app.redirect = false
                            app.alert = true
                            console.log(result)
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
                app.loading = false;
            },

            closeDialog: function(){
                this.alert = false
                if(this.redirect) window.location.href = "/"
            },
        }
    }

    
</script>