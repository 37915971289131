import Vue from 'vue'
import Login from './Login.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import liff from '@line/liff';

Vue.config.productionTip = false
Vue.prototype.$axios = axios;
Vue.prototype.$liff = liff;
// Vue.prototype.$hostUrl = 'http://localhost/insurefriend/';
// Vue.prototype.$hostUrl = 'https://line.carigoapp.com/';
var port = window.location.port;
if(port == '8080'){
	Vue.prototype.$hostUrl = 'http://localhost/insurefriend/api/';
} else if(port == ''){
	Vue.prototype.$hostUrl = 'https://line.carigoapp.com/api/';
}

new Vue({
  vuetify,
  router,
  render: h => h(Login)
}).$mount('#app')
